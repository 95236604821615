<script setup lang="ts">
import { onMounted, ref } from "vue";
import ErrorPage404 from "~/errors/404/ErrorPage404.vue";
import ErrorPage500 from "~/errors/500/ErrorPage500.vue";

type IErrorPageTypes = "404Error" | "500Error";

const errorObject = useError();
const { send } = useAnalytics();

const errorValue = errorObject.value;

const errorComponents: Record<IErrorPageTypes, any> = {
  "404Error": ErrorPage404,
  "500Error": ErrorPage500,
};

const template = ref<IErrorPageTypes | null>(null);

if (errorValue && "statusCode" in errorValue) {
  switch (String(errorValue.statusCode)) {
    case "404":
      template.value = "404Error";
      break;
    default:
      template.value = "500Error";
  }
}

onMounted(() => {
  send("Error:View", {
    error_type:
      errorValue && "statusCode" in errorValue ? errorValue.statusCode : "",
    error_message: errorValue?.message,
  });
});
</script>

<template>
  <div v-if="errorObject">
    <component :is="errorComponents[template]" v-if="template" />
  </div>
</template>
