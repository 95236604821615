import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MainPage:View": {
    ya: {
      eventName: "main_view",
      page: "main",
    },
  },
  "MainPage:UpperSlider:View": {
    ya: {
      eventName: "main_upperSlider_view",
      page: "main",
      block: "upperSlider",
    },
  },
  "MainPage:UpperSlider:Scroll": {
    ya: {
      eventName: "main_upperSlider_scroll",
      page: "main",
      block: "upperSlider",
      scroll_line: "",
    },
  },
  "MainPage:UpperSlider:Item:View": {
    ya: {
      eventName: "main_upperSlider_item_view",
      page: "main",
      block: "upperSlider",
      element: "item",
      position: null,
    },
  },
  "MainPage:UpperSlider:Item:Click": {
    ya: {
      eventName: "main_upperSlider_item_click",
      page: "main",
      block: "upperSlider",
      element: "item",
      dest: "",
      position: null,
    },
  },
  "MainPage:Specials:View": {
    ya: {
      eventName: "main_specials_view",
      page: "main",
      block: "specials",
    },
  },
  "MainPage:Specials:Scroll": {
    ya: {
      eventName: "main_specials_scroll",
      page: "main",
      block: "specials",
      scroll_line: "",
    },
  },
  "MainPage:Specials:Item:View": {
    ya: {
      eventName: "main_specials_item_view",
      page: "main",
      block: "specials",
      element: "item",
      position: null,
      title: "",
    },
  },
  "MainPage:Specials:Item:Click": {
    ya: {
      eventName: "main_specials_item_click",
      page: "main",
      block: "specials",
      element: "item",
      dest: "",
      position: null,
      title: "",
    },
  },
  "MainPage:Catalog:View": {
    ya: {
      eventName: "main_catalog_view",
      page: "main",
      block: "catalog",
    },
  },
  "MainPage:Catalog:Item:View": {
    ya: {
      eventName: "main_catalog_item_view",
      page: "main",
      block: "catalog",
      element: "item",
      category_name: "",
    },
  },
  "MainPage:Catalog:Item:Click": {
    ya: {
      eventName: "main_catalog_item_click",
      page: "main",
      block: "catalog",
      element: "item",
      category_name: "",
      dest: "",
    },
  },
  "MainPage:NewProducts:View": {
    ya: {
      eventName: "main_newProducts_view",
      page: "main",
      block: "newProducts",
    },
  },
  "MainPage:NewProducts:Scroll": {
    ya: {
      eventName: "main_newProducts_scroll",
      page: "main",
      block: "newProducts",
      scroll_line: "",
    },
  },
  "MainPage:NewProducts:Item:View": {
    ya: {
      eventName: "main_newProducts_item_view",
      page: "main",
      block: "newProducts",
      element: "item",
      item_id: null,
      item_name: "",
      position: null,
    },
  },
  "MainPage:NewProducts:Item:Click": {
    ya: {
      eventName: "main_newProducts_item_click",
      page: "main",
      block: "newProducts",
      element: "item",
      item_id: null,
      item_name: "",
      dest: "",
      position: null,
    },
  },
  "MainPage:NewProducts:Forward:Click": {
    ya: {
      eventName: "main_newProducts_forward_click",
      page: "main",
      block: "newProducts",
      element: "forward",
    },
  },
  "MainPage:NewProducts:AdFox:View": {
    ya: {
      eventName: "main_newProducts_adFox_goods_view",
      page: "main",
      block: "newProducts",
      element: "adFox_goods",
    },
  },
  "MainPage:NewProducts:AdFox:Click": {
    ya: {
      eventName: "main_newProducts_adFox_goods_click",
      page: "main",
      block: "newProducts",
      element: "adFox_goods",
      dest: "",
    },
  },
  "MainPage:LoyaltyPromo:View": {
    ya: {
      eventName: "main_loyaltyPromo_view",
      page: "main",
      block: "loyaltyPromo",
    },
  },
  "MainPage:LoyaltyPromo:Download:Click": {
    ya: {
      eventName: "main_loyaltyPromo_download_click",
      page: "main",
      block: "loyaltyPromo",
      element: "download",
    },
  },
  "MainPage:Exclusive:View": {
    ya: {
      eventName: "main_exclusive_view",
      page: "main",
      block: "exclusive",
    },
  },
  "MainPage:Exclusive:Scroll": {
    ya: {
      eventName: "main_exclusive_scroll",
      page: "main",
      block: "exclusive",
      scroll_line: "",
    },
  },
  "MainPage:Exclusive:Item:View": {
    ya: {
      eventName: "main_exclusive_item_view",
      page: "main",
      block: "exclusive",
      element: "item",
      position: null,
      title: "",
    },
  },
  "MainPage:Exclusive:Item:Click": {
    ya: {
      eventName: "main_exclusive_item_click",
      page: "main",
      block: "exclusive",
      element: "item",
      dest: "",
      position: null,
      title: "",
    },
  },
  "MainPage:Exclusive:Forward:Click": {
    ya: {
      eventName: "main_exclusive_forward_click",
      page: "main",
      block: "exclusive",
      element: "forward",
    },
  },
  "MainPage:Popular:View": {
    ya: {
      eventName: "main_popular_view",
      page: "main",
      block: "popular",
    },
  },
  "MainPage:Popular:Scroll": {
    ya: {
      eventName: "main_popular_scroll",
      page: "main",
      block: "popular",
      scroll_line: "",
    },
  },
  "MainPage:Popular:Item:View": {
    ya: {
      eventName: "main_popular_item_view",
      page: "main",
      block: "popular",
      element: "item",
      item_id: null,
      item_name: "",
      position: null,
    },
  },
  "MainPage:Popular:Item:Click": {
    ya: {
      eventName: "main_popular_item_click",
      page: "main",
      block: "popular",
      element: "item",
      item_id: null,
      item_name: "",
      dest: "",
      position: null,
    },
  },
  "MainPage:Popular:Forward:Click": {
    ya: {
      eventName: "main_popular_forward_click",
      page: "main",
      block: "popular",
      element: "forward",
    },
  },
  "MainPage:Useful:View": {
    ya: {
      eventName: "main_useful_view",
      page: "main",
      block: "useful",
    },
  },
  "MainPage:Useful:Scroll": {
    ya: {
      eventName: "main_useful_scroll",
      page: "main",
      block: "useful",
      scroll_line: "",
    },
  },
  "MainPage:Useful:Item:View": {
    ya: {
      eventName: "main_useful_item_view",
      page: "main",
      block: "useful",
      element: "item",
      item_name: "",
    },
  },
  "MainPage:Useful:Item:Click": {
    ya: {
      eventName: "main_useful_item_click",
      page: "main",
      block: "useful",
      element: "item",
      item_name: "",
      dest: "",
    },
  },
  "MainPage:Brands:View": {
    ya: {
      eventName: "main_brands_view",
      page: "main",
      block: "brands",
    },
  },
  "MainPage:Brands:Scroll": {
    ya: {
      eventName: "main_brands_scroll",
      page: "main",
      block: "brands",
      scroll_line: "",
    },
  },
  "MainPage:Brands:Item:View": {
    ya: {
      eventName: "main_brands_item_view",
      page: "main",
      block: "brands",
      element: "item",
      position: null,
      title: "",
    },
  },
  "MainPage:Brands:Item:Click": {
    ya: {
      eventName: "main_brands_item_click",
      page: "main",
      block: "brands",
      element: "item",
      dest: "",
      position: null,
      title: "",
    },
  },
  "MainPage:BottomSlider:View": {
    ya: {
      eventName: "main_bottomSlider_view",
      page: "main",
      block: "bottomSlider",
    },
  },
  "MainPage:BottomSlider:Scroll": {
    ya: {
      eventName: "main_bottomSlider_scroll",
      page: "main",
      block: "bottomSlider",
      scroll_line: "",
    },
  },
  "MainPage:BottomSlider:Item:View": {
    ya: {
      eventName: "main_bottomSlider_item_view",
      page: "main",
      block: "bottomSlider",
      element: "item",
      position: null,
      title: "",
    },
  },
  "MainPage:BottomSlider:Item:Click": {
    ya: {
      eventName: "main_bottomSlider_item_click",
      page: "main",
      block: "bottomSlider",
      element: "item",
      dest: "",
      position: null,
      title: "",
    },
  },
  "MainPage:Promo:NarrowAd:View": {
    ya: {
      eventName: "main_promo_view",
      page: "main",
      block: "promo",
      block_type: "narrowAd",
      title: "",
    },
  },
  "MainPage:Promo:NarrowAd:Click": {
    ya: {
      eventName: "main_promo_click",
      page: "main",
      block: "promo",
      block_type: "narrowAd",
      dest: "",
      title: "",
    },
  },
  "MainPage:Promo:Reg:View": {
    ya: {
      eventName: "main_promoReg_view",
      page: "main",
      block: "promoReg",
      block_type: "wideAd",
    },
  },
  "MainPage:Promo:Reg:Click": {
    ya: {
      eventName: "main_promoReg_click",
      page: "main",
      block: "promoReg",
      block_type: "wideAd",
      title: "",
    },
  },
  "MainPage:AdFox:View": {
    ya: {
      eventName: "main_adFox_view",
      page: "main",
      block: "adFox",
      placement: "",
    },
  },
  "MainPage:AdFox:Promo:View": {
    ya: {
      eventName: "main_adFox_promo_view",
      page: "main",
      block: "adFox",
      element: "promo",
      placement: "",
    },
  },
  "MainPage:AdFox:Promo:Click": {
    ya: {
      eventName: "main_adFox_promo_click",
      page: "main",
      block: "adFox",
      element: "promo",
      placement: "",
      dest: "",
    },
  },
  "MainPage:AdFox:PWA:View": {
    ya: {
      eventName: "main_promoPWA_view",
      page: "main",
      block: "promoPWA",
    },
  },
  "MainPage:AdFox:PWA:Click": {
    ya: {
      eventName: "main_promoPWA_click",
      page: "main",
      block: "promoPWA",
    },
  },
};
