import type { IJWT } from "~/utils/jwt";
import { privateRoutes, Routes } from "~~/utils/routes";

export default defineNuxtRouteMiddleware((to, _) => {
  const jwt = useCookie<IJWT>(APP_COOKIE_STORAGE_KEYS.jwt);
  const magnitId = useCookie<string>(APP_COOKIE_STORAGE_KEYS.magnitIDCode);
  const incompleteProfile = useCookie<string>(
    APP_COOKIE_STORAGE_KEYS.incompleteProfile,
  );

  if (!jwt.value?.access) {
    if (magnitId.value && to.path === Routes.Registration) {
      return;
    }

    if (
      privateRoutes.includes(to.path) ||
      to.path === Routes.ProfileIncomplete
    ) {
      return navigateTo(Routes.Main);
    }
  } else {
    if (incompleteProfile.value && privateRoutes.includes(to.path)) {
      return navigateTo(Routes.ProfileIncomplete);
    }

    if (to.path === Routes.Registration) {
      return navigateTo(Routes.Profile);
    }
  }
});
