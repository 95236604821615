import revive_payload_client_Frd8gASYWa from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l19uMh4P89 from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_T5aD6w6QZh from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_aQoh9E5uaZ from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import analytic_YVTAjAIn3G from "/app/src/plugins/analytic.ts";
import error_ldt3PQauZ0 from "/app/src/plugins/error.ts";
import fingerprint_2ufeYYZ2V8 from "/app/src/plugins/fingerprint.ts";
import logger_kEXgbufg9p from "/app/src/plugins/logger.ts";
import piniaReset_GJCT4uTrKH from "/app/src/plugins/piniaReset.ts";
import v_mask_dMN5UXPVua from "/app/src/plugins/v-mask.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  _0_siteConfig_l19uMh4P89,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  analytic_YVTAjAIn3G,
  error_ldt3PQauZ0,
  fingerprint_2ufeYYZ2V8,
  logger_kEXgbufg9p,
  piniaReset_GJCT4uTrKH,
  v_mask_dMN5UXPVua
]