export const validateAlphabetic = (str: string): boolean => {
  return /^[a-zа-яё]+$/.test(str.toLowerCase());
};

export const validateEmail = (str: string): boolean => {
  return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
    str.toLowerCase(),
  );
};

export const validateNumber = (number: string | number): boolean => {
  const value = number.toString().replace(/\s/g, "");
  return /^\d+$/.test(value);
};

export const validateFutureDate = (date: Date): boolean => {
  return Date.now() > date.getTime();
};

export const validate18YearsOldDate = (date: Date): boolean => {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  return age >= 18;
};
