import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Toolbar:CityStore:View": {
    ya: {
      eventName: "toolBar_CityStore_view",
      block: "toolBar",
      selected_store: "",
      selected_city: "",
    },
  },
  "Toolbar:CityStore:Click": {
    ya: {
      eventName: "toolBar_CityStore_click",
      block: "toolBar",
      selected_store: "",
      selected_city: "",
    },
  },
  "Toolbar:Search:View": {
    ya: {
      eventName: "toolBar_search_view",
      block: "toolBar",
    },
  },
  "Toolbar:Search:Click": {
    ya: {
      eventName: "toolBar_search_click",
      block: "toolBar",
    },
  },
  "Toolbar:Catalog:Button:View": {
    ya: {
      eventName: "toolBar_catalog_view",
      block: "toolBar",
    },
  },
  "Toolbar:Catalog:Button:Click": {
    ya: {
      eventName: "toolBar_catalog_click",
      block: "toolBar",
    },
  },
  "Toolbar:Auth:View": {
    ya: {
      eventName: "toolBar_sett_view",
      block: "toolBar",
    },
  },
  "Toolbar:Auth:Click": {
    ya: {
      eventName: "toolBar_sett_click",
      block: "toolBar",
    },
  },
};
