enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  geohelper: {
    /**
     * Поиск магазина по ID или Code. В запросе требуется передать только один из параметров.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/geohelper/get_v1_stores_store
     */
    store: "/v1/stores/store",
  },
  otp: {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: hhttps://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },
  auth: {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },
  user: {
    /**
     * Получаем информацию по бонусам и магнитикам
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/balance/get_v2_user_bonus_balance
     */
    balance: "/v2/user/bonus-balance",
    /**
     * Получаем карточки пользователя
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/get_v2_user_cards
     */
    cards: "/v2/user/cards",
    /**
     * Смерживаем вертуальную и физическую карты
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v3_user_card_merge
     */
    cardMerge: "/v3/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV3
     */
    profile: "/v1/profile",
    /**
     * Разрешения пользователя на получение sms, email, чеков на email.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/profiles/get_v1_profile_permissions
     */
    permissions: "/v2/profile/permissions",
  },

  city: {
    /**
     * Получаем информации о городе по IP пользователя
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_geolocation_ip
     */
    ip: "/v1/geolocation/ip",
    /**
     * Получаем информации о городе по координатам пользователя
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_geolocation_city
     */
    coords: "/v1/geolocation/city",
    /**
     * Получаем список городов, в которых есть магазины
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_cities
     */
    cities: "/v1/cities",
    /**
     * Получаем список магазинов в городе
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_stores
     */
    stores: "/v1/stores",
  },

  promo: {
    /**
     * Получаем список промо-категорий
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/promotions/get_v1_promotions_categories
     */
    categories: "/v1/promotions/categories",
    /**
     * Получаем список промо-товаров по категории
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/promotions/get_v1_promotions_categories
     */
    products: "/v1/promotions",
    /**
     * Получаем информации о промо-товаре
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/promotions/get_v2_promotions_item
     */
    product: "/v2/promotions/item",
    /**
     * Получаем товаров по типу акции
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/promotions/get_v1_promotions_type
     */
    type: "/v1/promotions/type",

    /**
     * Полнотекстовый поиск промоакций
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/promotions/get_v1_promotions_search
     */
    search: "/v1/promotions/search",
  },

  goods: {
    /**
     * Получаем список категорий
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/goods/get_v1_goods_categories
     */
    categories: "/v2/goods/categories",
    /**
     * Получение всех товаров определенных категорий в указанных магазинах.
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/goods/post_v3_goods
     */
    products: "/v3/goods",
    /**
     * Получение товаров согласно переданному списку id товаров и коду магазина
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/goods/post_v1_goods_store
     */
    productsByIDs: "/v1/goods/store",
    /**
     * Получаем информации о товаре
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/goods/get_v2_goods_item
     */
    product: "/v2/goods/item",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/goods/post_v1_goods_filters
     */
    filters: "/v1/goods/filters",
  },

  content: {
    /**
     * Данные для главной страницы.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_main_page
     */
    mainPage: "/v1/cosmetic/content/main-page",
    /**
     * Данные для страницы промо-каталога
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_catalog
     */
    catalog: "/v1/cosmetic/content/catalog",
    /**
     * Данные для страницы cookie.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_cookies
     */
    cookie: "/v1/cosmetic/content/cookies",
    /**
     * Получение статьи по ID.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_cookies
     */
    article: "/v1/cosmetic/content/article",
    /**
     * Получение публикаций.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/post_v1_cosmetic_content_publications
     */
    publications: "/v1/cosmetic/content/publications",
    /**
     * Получение брендированных товарных категорий.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/cosmetic/get_v1_cosmetic_content_branded_goods_categories
     */
    brandedGoodsCategories: "/v1/cosmetic/content/branded-goods-categories",
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_address
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_geo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_geo
     */
    store: "/v1/store-search",
  },
};

export { urls, HttpCodes, ErrorCodes };
