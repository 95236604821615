import type { FetchError } from "ofetch";
import { useRouter } from "vue-router";
import { ErrorCodes, HttpCodes } from "~/api/config";
import { privateRoutes, Routes } from "~/utils/routes";

const useResponseCheck = () => {
  const router = useRouter();
  const route = useRoute();

  const hasError = async (error: FetchError) => {
    if (
      error.statusCode === HttpCodes.UnprocessableEntity &&
      error.data?.code === ErrorCodes.IncompleteProfile
    ) {
      Cookie.set(APP_COOKIE_STORAGE_KEYS.incompleteProfile, "1");

      if (privateRoutes.includes(route.path)) {
        await router.push(Routes.ProfileIncomplete);
      }

      return true;
    }

    return false;
  };

  return {
    hasError,
  };
};

export default useResponseCheck;
