import useAnalyticManager from "@magnit/analytic-events/src/hook";
import projectEvents from "~/config/events";
import { RouteNames } from "~/utils/routes";

export default () => {
  const route = useRoute();
  const { send: dispatch, events } = useAnalyticManager();

  const userStore = useUserStore();

  const savedUserId = useLocalStorage<{
    uuid: string;
  }>(APP_LOCAL_STORAGE_KEYS.uuid, {
    uuid: "",
  });

  events(projectEvents);

  enum AnalyticPages {
    Main = "main",
    CardDetail = "cardDetail",
    CatalogGoods = "catalog",
    CatalogPromo = "promo",
    CatalogSearch = "searchList",
    Profile = "sett",
  }

  const analyticPagesMatching: { [key: string]: AnalyticPages } = {
    index: AnalyticPages.Main,
    profile: AnalyticPages.Profile,
    "profile-bonuses": AnalyticPages.Profile,
    "profile-stickers": AnalyticPages.Profile,
    "promo-catalog": AnalyticPages.CatalogPromo,
    "promo-catalog-category": AnalyticPages.CatalogPromo,
    "promo-catalog-category-product": AnalyticPages.CardDetail,
    "catalog-category": AnalyticPages.CatalogGoods,
  };

  const send = (name: string, params: Record<string, any> = {}) => {
    const event = projectEvents[name];
    const props = <Record<string, any>>{
      ...params,
    };

    props.magnit_id =
      savedUserId.value.uuid || userStore.profile.userId || null;

    // Если в описании события есть page и он не имеет значения, то оно будет подставлено автоматически
    if (event.ya && "page" in event.ya && !event.ya.page) {
      const pageName =
        analyticPagesMatching[route.name as RouteNames] || route.fullPath;

      props.page = pageName;
      if ("eventName" in event.ya && pageName !== route.fullPath) {
        props.eventName = `${pageName}_${event.ya.eventName}`;
      }
    }

    return dispatch(name, props);
  };
  return { send };
};
