<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useToggle } from "@vueuse/core";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import type {
  INotificationProps,
  INotificationEmits,
} from "./VNotification.types";

defineProps<INotificationProps>();
const emit = defineEmits<INotificationEmits>();

const [active, toggle] = useToggle();

const el = ref();

onMounted(() => {
  document.body.appendChild(el.value);
  toggle(true);
  scrollLock.enable();
});

onBeforeUnmount(() => {
  scrollLock.disable();
});

watch(active, (next) => {
  next ? scrollLock.enable() : scrollLock.disable();
});
</script>

<template>
  <div ref="el">
    <transition name="notification_fade">
      <div v-if="active" class="notification">
        <div class="notification__inner">
          <div v-if="icon" class="notification__icon">
            <component :is="icon" />
          </div>

          <div class="notification__title">
            <VText font="headline-small">{{ title }}</VText>
          </div>

          <div class="notification__text">
            <VText>{{ text }}</VText>
          </div>

          <VButton
            full-width
            @click="
              () => {
                toggle(false);
                emit('primaryClick');
              }
            "
          >
            {{ primaryButtonText || "Назад" }}
          </VButton>

          <VButton
            v-if="secondaryButtonText"
            class="notification__secondary"
            full-width
            @click="
              () => {
                toggle(false);
                emit('secondaryClick');
              }
            "
          >
            {{ secondaryButtonText }}
          </VButton>
        </div>

        <div class="notification__scrim" @click="toggle(false)" />
      </div>
    </transition>
  </div>
</template>

<style lang="postcss">
.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--pl-z-index-overlay);

  &__scrim {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    cursor: pointer;
    opacity: 0.65;
    z-index: var(--pl-z-index-negative);
  }

  &__inner {
    width: 340px;
    padding: 16px;
    background-color: var(--pl-surface-muted-default);
    border-radius: var(--pl-radius-notification-s);
  }

  &__icon {
    margin-bottom: 24px;
    text-align: center;
  }

  &__title {
    text-align: center;
    margin-bottom: 8px;
  }

  &__text {
    text-align: center;
    margin-bottom: 24px;
  }

  &__secondary {
    margin-top: 16px;
  }
}

.notification_fade-enter-active,
.notification_fade-leave-active {
  transition: opacity 0.3s ease;
}

.notification_fade-enter-from,
.notification_fade-leave-to {
  opacity: 0;
}
</style>
