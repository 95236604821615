import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Search:Results:List:View": {
    ya: {
      eventName: "searchList_view",
      block: "searchList",
    },
  },
  "Search:Button:Find:Click": {
    ya: {
      eventName: "toolBar_findSearch_click",
      block: "toolBar",
      element: "findSearch",
    },
  },
  "Search:Button:More:Click": {
    ya: {
      eventName: "searchList_more_click",
      block: "searchList",
      element: "more",
    },
  },
  "Search:Page:List:View": {
    ya: {
      eventName: "searchRes_list_view",
      page: "searchRes",
      element: "list",
    },
  },
  "Search:Page:Item:Click": {
    ya: {
      eventName: "searchRes_item_click",
      page: "searchRes",
      element: "item",
    },
  },
  "Search:Field:Cancel:Click": {
    ya: {
      eventName: "toolBar_cancelSearchField_click",
      block: "toolBar",
      element: "cancelSearchField",
    },
  },
  "Search:Field:Clear:Click": {
    ya: {
      eventName: "toolBar_cleanSearchField_click",
      block: "toolBar",
      element: "cleanSearchField",
    },
  },
  "Search:Error:View": {
    ya: {
      eventName: "error_noProduct_view",
      block: "error",
    },
  },
  "Search:Error:Button:Click": {
    ya: {
      eventName: "error_to_prmMain_click",
      block: "error",
      element: "to_prmMain",
    },
  },
};
