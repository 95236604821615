import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Article:View": {
    ya: {
      eventName: "content_view",
      page: "content",
      title: "",
    },
  },
  "Article:Share:Click": {
    ya: {
      eventName: "content_share_click",
      page: "content",
      element: "share",
      socials: "",
    },
  },
  "Article:Goods:View": {
    ya: {
      eventName: "content_goods_view",
      page: "content",
      block: "goods",
    },
  },
  "Article:Goods:Scroll": {
    ya: {
      eventName: "content_goods_scroll",
      page: "content",
      block: "goods",
      scroll_line: "",
    },
  },
  "Article:Goods:Item:View": {
    ya: {
      eventName: "content_goods_item_view",
      page: "content",
      block: "goods",
      element: "item",
      item_id: "",
      item_name: "",
      position: "",
    },
  },
  "Article:Goods:Item:Click": {
    ya: {
      eventName: "content_goods_item_click",
      page: "content",
      block: "goods",
      element: "item",
      item_id: "",
      item_name: "",
      dest: "",
      position: "",
    },
  },
  "Article:Goods:Forward:Click": {
    ya: {
      eventName: "content_goods_forward_click",
      page: "content",
      block: "goods",
      element: "forward",
    },
  },
  "Article:Other:View": {
    ya: {
      eventName: "content_otherContent_view",
      page: "content",
      block: "otherContent",
    },
  },
  "Article:Other:Item:View": {
    ya: {
      eventName: "content_otherContent_item_view",
      page: "content",
      block: "otherContent",
      element: "item",
      item_name: "",
    },
  },
  "Article:Other:Item:Click": {
    ya: {
      eventName: "content_otherContent_item_click",
      page: "content",
      block: "otherContent",
      element: "item",
      item_name: "",
      dest: "",
    },
  },
};
