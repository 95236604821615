import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Header:CityStore:View": {
    ya: {
      eventName: "toolBar_CityStore_view",
      element: "CityStore",
      block: "toolBar",
    },
  },
  "Header:CityStore:Click": {
    ya: {
      eventName: "toolBar_CityStore_click",
      element: "CityStore",
      block: "toolBar",
    },
  },
};
