const UAT_MARKER = "uat";
const DEV_MARKER = "dev";
const localhosts = ["localhost", "127.0.0.1"];
const getCookieDomain = (hostname: string) => {
  return !localhosts.includes(hostname)
    ? hostname.includes(UAT_MARKER)
      ? `.${UAT_MARKER}${hostname.split(UAT_MARKER)[1]}`
      : hostname.includes(DEV_MARKER)
        ? `.${DEV_MARKER}${hostname.split(DEV_MARKER)[1]}`
        : `.${hostname.split(".").slice(-2).join(".")}`
    : undefined;
};

export default getCookieDomain;
