import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "StoreSelect:StoreOffer:View": {
    ya: {
      eventName: "cityStoreOffer_view",
      block: "cityStoreOffer",
      page: "",
      store_id: "",
      store_name: "",
      store_code: "",
      store_city: "",
      store_address: "",
    },
  },
  "StoreSelect:StoreOffer:Ok:Click": {
    ya: {
      eventName: "cityStoreOffer_ok_click",
      block: "cityStoreOffer",
      element: "ok",
      page: "",
      store_id: "",
      store_name: "",
      store_code: "",
      store_city: "",
      store_address: "",
    },
  },
  "StoreSelect:StoreOffer:Change:Click": {
    ya: {
      eventName: "cityStoreOffer_change_click",
      block: "cityStoreOffer",
      element: "change",
      page: "",
      store_id: "",
      store_name: "",
      store_code: "",
      store_city: "",
      store_address: "",
    },
  },
  "StoreSelect:MapStore:View": {
    ya: {
      eventName: "storeSelect_mapStore_view",
      block: "mapStore",
      page: "storeSelect",
    },
  },
  "StoreSelect:MapStore:Close:Click": {
    ya: {
      eventName: "storeSelect_mapStore_close_click",
      block: "mapStore",
      element: "close",
      page: "storeSelect",
    },
  },
  "StoreSelect:MapStore:Store:Click": {
    ya: {
      eventName: "storeSelect_mapStore_store_click",
      block: "mapStore",
      element: "store",
      page: "storeSelect",
      store_id: "",
      store_name: "",
      store_code: "",
      store_city: "",
      store_address: "",
    },
  },
  "StoreSelect:ListStore:View": {
    ya: {
      eventName: "storeSelect_listStore_view",
      block: "listStore",
      page: "storeSelect",
    },
  },
  "StoreSelect:ListStore:List:Click": {
    ya: {
      eventName: "storeSelect_listStore_list_click",
      block: "listStore",
      element: "list",
      page: "storeSelect",
    },
  },
  "StoreSelect:ListStore:Store:Click": {
    ya: {
      eventName: "storeSelect_listStore_store_click",
      block: "listStore",
      element: "store",
      page: "storeSelect",
      store_id: "",
      store_name: "",
      store_code: "",
      store_city: "",
      store_address: "",
    },
  },
  "StoreSelect:SearchStore:Click": {
    ya: {
      eventName: "storeSelect_searchStore_click",
      element: "searchStore",
      page: "storeSelect",
      query: "",
    },
  },
  "StoreSelect:Geo:Click": {
    ya: {
      eventName: "storeSelect_geo_click",
      element: "geo",
      page: "storeSelect",
    },
  },
  "StoreSelect:Change:Click": {
    ya: {
      eventName: "storeSelect_change_click",
      element: "change",
      page: "storeSelect",
    },
  },
  "StoreSelect:Error:SearchStore:View": {
    ya: {
      eventName: "storeSelect_error_searchStore_view",
      block: "error",
      element: "searchStore",
      type_error: "no_stores",
      page: "storeSelect",
      query: "",
    },
  },
};
