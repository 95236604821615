import { computed } from "vue";

export default () => {
  const runtimeConfig = useRuntimeConfig();

  const config = computed(() => runtimeConfig.public);
  const yandexCaptcha = computed(() => config.value?.yandexCaptcha);
  const kfpCid = computed(() => config.value?.kfpCid);
  const version = computed(() => config.value?.version);
  const debug = computed(() => config.value?.debug);
  const adfoxOwnerId = computed(() => config.value?.adfoxOwnerId);
  const cookieDomain = computed(() => config.value?.cookieDomain);

  return {
    config,
    yandexCaptcha,
    kfpCid,
    version,
    debug,
    adfoxOwnerId,
    cookieDomain,
  };
};
