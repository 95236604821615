/* eslint-disable no-console */
import {
  type ILoggerPlugin,
  type LoggerCreatedFunction,
  LOGGER_PREFIXES,
} from "~/typings/plugins/logger";

export default defineNuxtPlugin(() => {
  const { debug } = usePublicConfig();

  const randomColorPallete = [
    "#a21232",
    "#453953",
    "#f9b248",
    "#efd510",
    "#29a19c",
    "#a696c8",
    "#4d6de3",
    "#5a082d",
    "#50cb86",
    "#393e46",
    "#6173f4",
  ];

  const colorsMap: Map<LOGGER_PREFIXES, string> = new Map([
    [LOGGER_PREFIXES.PubSub, "#ACB1D6"],
    [LOGGER_PREFIXES.Request, "#68B984"],
    [LOGGER_PREFIXES.RequestError, "#DF2E38"],
    [LOGGER_PREFIXES.Component, "5D9C59"],
    [LOGGER_PREFIXES.ComponentError, "#DF2E38"],
    [LOGGER_PREFIXES.Error, "#DF2E38"],
    [LOGGER_PREFIXES.Replacement, "#617A55"],
    [LOGGER_PREFIXES.Cache, "#967E76"],
    [LOGGER_PREFIXES.Statistics, "#5C469C"],
    [LOGGER_PREFIXES.Profiling, "#D83F31"],
  ]);
  const loggerMap: Map<string, LoggerCreatedFunction> = new Map();

  const getPrefix = (prefix: string) => {
    const dateStr = new Date().toLocaleTimeString();
    return `%c[${prefix.toUpperCase()}:${dateStr}]`;
  };

  const getRandomColorFromPalette = () => {
    const randomIndex = Math.floor(Math.random() * randomColorPallete.length);
    return randomColorPallete[randomIndex] ?? randomColorPallete[0];
  };

  const getStyle = (color: string) => {
    return `background-color: ${color}; color: #fff; font-size: 14px; padding: 3px`;
  };

  const log = (prefix: string, color: string, ...toLog: any[]) => {
    console.log(getPrefix(prefix), getStyle(color), ...toLog);
  };

  const warn = (prefix: string, color: string, ...toLog: any[]) => {
    console.warn(getPrefix(prefix), getStyle(color), ...toLog);
  };

  const error = (prefix: string, color: string, ...toLog: any[]) => {
    console.error(getPrefix(prefix), getStyle(color), ...toLog);
  };

  const table = (prefix: string, color: string, ...toLog: any[]) => {
    console.log(getPrefix(prefix), getStyle(color));
    console.table(...toLog);
  };

  const createInstance = (argPrefix: string, argColor = "", type = "") => {
    const prefix = argPrefix || "no_prefix";
    if (debug.value !== "true")
      return (..._toLog: any[]) => {
        /* void */
      };
    const color = argColor || getRandomColorFromPalette();
    if (type === "error")
      return (...toLog: any[]) => error(prefix, color, ...toLog);
    if (type === "warn")
      return (...toLog: any[]) => warn(prefix, color, ...toLog);
    if (type === "table")
      return (...toLog: any[]) => table(prefix, color, ...toLog);
    if (type === "log")
      return (...toLog: any[]) => log(prefix, color, ...toLog);
    return (...toLog: any[]) => log(prefix, color, ...toLog);
  };

  const create = (prefix: LOGGER_PREFIXES, type = "log") => {
    const entry = loggerMap.get(prefix);
    if (entry) return entry;
    let resultPrefix = prefix;
    if (prefix === LOGGER_PREFIXES.RequestError)
      resultPrefix = LOGGER_PREFIXES.Request;
    if (prefix === LOGGER_PREFIXES.ComponentError)
      resultPrefix = LOGGER_PREFIXES.Component;
    const color = colorsMap.get(prefix) ?? getRandomColorFromPalette();
    const newEntry = createInstance(resultPrefix, color, type);
    loggerMap.set(prefix, newEntry);
    return newEntry;
  };

  const get = (prefix: LOGGER_PREFIXES, type = "log") => {
    const entry = loggerMap.get(prefix);
    if (entry) return entry;
    const newEntry = create(prefix, type);
    loggerMap.set(prefix, newEntry);
    return newEntry;
  };

  const logger: ILoggerPlugin = { log, warn, error, create, get };

  return {
    provide: { logger },
  };
});
