import { load } from "@fingerprintjs/fingerprintjs";
import { formatToUid } from "~/utils/etc";

export default defineNuxtPlugin(async () => {
  const uidCookie = useCookie(APP_COOKIE_STORAGE_KEYS.uniqDeviceID);

  if (process.server || uidCookie.value) return;

  const fp = await load();
  const { visitorId } = await fp.get();

  uidCookie.value = formatToUid(visitorId).toUpperCase();
});
