import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Navigation:Top:View": {
    ya: {
      eventName: "upperTapBar_view",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:Magnit:Click": {
    ya: {
      eventName: "upperTapBar_magnit_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:Dostavka:Click": {
    ya: {
      eventName: "upperTapBar_delivery_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:Cosmetic:Click": {
    ya: {
      eventName: "upperTapBar_cosmetic_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:Apteka:Click": {
    ya: {
      eventName: "upperTapBar_apteka_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:More:Click": {
    ya: {
      eventName: "upperTapBar_more_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:Item:Phone:Click": {
    ya: {
      eventName: "upperTapBar_number_click",
      block: "upperTapBar",
    },
  },
  "Navigation:Top:More:Link:Click": {
    ya: {
      eventName: "upperTapBar_more_items_click",
      block: "upperTapBar",
      element: "more_items",
    },
  },
};
