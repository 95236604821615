import type { UseFetchOptions } from "nuxt/app";
import { useFetch } from "nuxt/app";
import { useAuthStore } from "~/store/auth";
import { type IJWT, getJWTExpiration, isJWTExpired } from "~/utils/jwt";

interface IRequestParams<T, D = T> extends UseFetchOptions<T, D> {
  gateway?: "web" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
}

const getJWT = async (): Promise<IJWT | null> => {
  if (typeof window === "undefined") {
    return null;
  }

  let JWT = Cookie.get(APP_COOKIE_STORAGE_KEYS.jwt, true);

  if (!JWT) {
    return null;
  }

  const expiration = getJWTExpiration(JWT.access);

  if (expiration === null) {
    throw new Error("Invalid token");
  }

  if (isJWTExpired(expiration)) {
    const authStore = useAuthStore();
    await authStore.refresh();

    JWT = Cookie.get(APP_COOKIE_STORAGE_KEYS.jwt, true);
  }

  return JWT;
};

const getPermissionJWT = async (): Promise<string> => {
  const JWT = await getJWT();

  if (!JWT) {
    throw new Error("Отсутствует JWT токен");
  }

  return `Bearer ${JWT.access}`;
};

export const useTransport = async <R = void, D = R>(
  url: string,
  params?: IRequestParams<R, D>,
) => {
  const {
    permissions,
    gateway = "web",
    headers = {},
    ...restParams
  } = params || {};
  const reqHeaders = useRequestHeaders();
  let _headers: HeadersInit = apiGetHeaders({
    ...headers,
    ...reqHeaders,
  } as Record<string, string>);

  if (gateway === "web") {
    _headers = {
      ..._headers,
      "X-Client-Name": "cosmetic",
    };
  }

  if (permissions?.jwt) {
    const Authorization = await getPermissionJWT();

    _headers = {
      ..._headers,
      Authorization,
    };
  }

  return useFetch(url, {
    baseURL: gateway === "web" ? "/webgate" : "/magnit-id",
    headers: _headers,
    ...restParams,
  });
};
