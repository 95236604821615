import { AnalyticManagerPlugin } from "@magnit/analytic-events";
import cookiePolicyEvents from "@magnit/unit-cookie-policy/src/cookie-policy.events";
import footerEvents from "@magnit/unit-footer/src/footer.events";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(AnalyticManagerPlugin, {
    config: {
      ya: {
        counterId: 92154139,
      },
    },
    events: {
      ...cookiePolicyEvents,
      ...footerEvents,
    },
  });
});
