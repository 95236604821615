// @NOTE: returns 22.10.2022
export const getFormattedDDMMYYYY = (newDate: Date): string => {
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${String(date).padStart(2, "0")}.${String(month).padStart(
    2,
    "0",
  )}.${year}`;
};

// @NOTE: returns 2022-10-22
export const getFormattedYYYYMMDD = (newDate: Date): string => {
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}-${String(month).padStart(2, "0")}-${String(date).padStart(
    2,
    "0",
  )}`;
};

/**
 * Преобразуем дату из строки вида "2022-10-22" в Date
 */
export const getDateFromYYYYMMDD = (date: string): Date => {
  const [yyyy, mm, dd] = date.split("-");
  return new Date(Number(yyyy), Number(mm) - 1, Number(dd));
};

/**
 * Выводим Date в виде "19 апреля"
 */
export const formatDateToDDMMMM = (date: Date): string => {
  const monthNames = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const dd = date.getDate();
  const mm = date.getMonth();
  return `${dd} ${monthNames[mm]}`;
};

// "7 декабря 2024 (вторник)"
export const formatDateToDMMMMYYYWd = (date: Date) => {
  const dateStr = date
    .toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
    })
    .slice(0, -3);
  const weekday = date.toLocaleDateString("ru-RU", { weekday: "long" });
  return `${dateStr} (${weekday})`;
};
