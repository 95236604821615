import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "CardDetail:View": {
    ya: {
      eventName: "cardDetail_view",
      page: "cardDetail",
      categoryName: "",
      name: "",
      product_id: "",
      price: "",
      old_price: "",
      discountCategory: "",
      discountPercentage: "",
      discountType: "",
      discountValue: "",
      previewDiscountTitle: "",
      progressiveDiscountType: "",
      progressiveDiscount: [],
      startDate: "",
      endDate: "",
    },
  },
  "CardDetail:Share:View": {
    ya: {
      eventName: "cardDetail_share_view",
      page: "cardDetail",
      element: "share",
    },
  },
  "CardDetail:Share:Click": {
    ya: {
      eventName: "cardDetail_share_click",
      page: "cardDetail",
      element: "share",
    },
  },
  "CardDetail:ShareSocials:Click": {
    ya: {
      eventName: "cardDetail_shareSocials_click",
      page: "cardDetail",
      element: "shareSocials",
      categoryName: "",
      name: "",
      product_id: "",
      discountCategory: "",
      social_type: "",
    },
  },
  "CardDetail:AdFox:View": {
    ya: {
      eventName: "cardDetail_adFox_view",
      page: "cardDetail",
      block: "adFox",
    },
  },
  "CardDetail:AdFox:Click": {
    ya: {
      eventName: "cardDetail_adFox_click",
      page: "cardDetail",
      block: "adFox",
    },
  },
  "CardDetail:Tab:View": {
    ya: {
      eventName: "cardDetail_description_view",
      page: "cardDetail",
      block: "description",
      description: "",
    },
  },
};
