export const apiGetHeaders = (headers: Record<string, string>) => {
  const ksid = Cookie.get(APP_COOKIE_STORAGE_KEYS.ksid);
  const deviceId =
    Cookie.get(APP_COOKIE_STORAGE_KEYS.uniqDeviceID) || "omit empty";
  const userAgent =
    (import.meta.client ? window.navigator.userAgent : headers["user-agent"]) ??
    "";
  let platformVersion = "omit empty";
  if (import.meta.client) {
    const { os, browser, version } = getBrowserInfo();
    platformVersion = `${os} ${browser} ${version}`;
  }

  return {
    ...toValue(headers),
    "Content-Type": "application/json",
    "User-Agent": userAgent,
    "X-Platform-Version": platformVersion,
    "X-Device-Tag": ksid || "disabled",
    "X-Device-ID": deviceId,
    "X-App-Version": APP_VERSION,
    "X-Device-Platform": "Web",
    "X-Client-Name": "cosmetic",
  };
};

export const apiGetWebFetchConfig = (
  url: string,
  headers: Record<string, string>,
  extra: Record<string, unknown> = {},
) => ({
  url: stripDoubleSlash(`/webgate/${url}`),
  config: {
    headers: apiGetHeaders(headers),
    ...extra,
  },
});
